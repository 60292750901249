@import '@/style/theme.less';

@pkg: fle-layout-tab-menu;

.@{pkg} {
  margin: 16px 16px 0;
  padding: 0 26px;
  border-radius: 2px;
  background-color: #fff;
  z-index: 1;

  .fle-tabs {
    .fle-tabs-tab {
      height: 40px;
    }

    &.fle-tabs-top > .fle-tabs-nav {
      margin: 0;

      &::before {
        content: none;
      }
    }
  }
}
@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;