@import '@/style/theme.less';
@import '@/style/extends.less';

.fle-layout-sider {
  height: 100%;
  z-index: 12;
  background-color: transparent !important;

  .fle-menu-inline-collapsed {
    .fle-menu-item,
    .fle-menu-submenu .fle-menu-submenu-title {
      padding: 0 16px;
    }
  }

  .fle-menu {
    &-title {
      position: fixed;
      top: 56px;
      height: 36px;
      width: 180px;
      font-weight: 600;
      color: #999;
      padding: 0 16px;
      background-color: #fff;
    }

    &-space {
      width: 180px;
    }

    &-wrap {
      .menu-action-wrap {
        position: fixed;
        bottom: 20px;
        left: 168px;
        width: 24px;
        height: 24px;
        text-align: center;
        border-radius: 40px;
        inset-inline-end: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.25);
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease 0s;
        z-index: 1;

        &:hover .menu-action-close-icon,
        &:hover .menu-action-open-icon {
          color: #333;
        }

        .menu-action-close-icon,
        .menu-action-open-icon {
          font-size: 12px;
        }
      }

      .fle-menu-root {
        width: 100%;
      }

      .fle-menu-sider {
        position: fixed;
        top: 56px;
        height: calc(100% - 56px);
        padding: 16px 3px 40px;
        background-color: #fff;
        transition: all 0.2s ease 0s;
        border-right: 1px solid #f0f0f0;
        overflow-y: auto;
        overflow-x: hidden;

        &.fle-scroll:hover {
          &::-webkit-scrollbar-thumb,
          &::-webkit-scrollbar-track {
            display: block;
          }
        }

        .fle-menu-sider-item {
          margin-bottom: 8px;

          .fle-first-menu {
            height: 36px;
            .flex-space-between();

            &-item {
              .flex();
              line-height: 16px;
              cursor: pointer;

              &:hover * {
                color: @primary-color;
              }

              .anticon {
                margin-right: 6px;
                font-size: 16px;
                color: #888;
              }

              span {
                font-weight: 600;
                color: #333;
              }

              &.active * {
                color: @primary-color;
              }
            }

            &-action {
              color: #777;
              font-size: 16px;
              line-height: 16px;
            }
          }

          .fle-sub-menu {
            .flex-start();
            flex-wrap: wrap;

            &-item {
              height: 28px;
              font-size: 12px;
              width: 50%;
              color: rgb(86, 89, 96);
              padding: 6px 0;
              cursor: pointer;

              &:hover {
                color: @primary-color;
              }

              &.active {
                font-weight: 500;
                color: @primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;